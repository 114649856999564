//NEXT Js Head
import NEXTHead from "next/head";

//Default Head reusable for all pages
const Head = ({ title, content }) => {
  let appName;

  //App Name
  switch (process.env.NEXT_PUBLIC_THEME) {
    case "SN":
      appName = "Salone Nautico";
      break;

    case "SDM":
      appName = "Salone del Mobile";
      break;

    case "SVT":
      appName = "Adunata Nazionale 2024";
      break;

    case "COLLAUDO":
      appName = "dTicketing";
      break;

    default:
      break;
  }

  return (
    <NEXTHead>
      <meta charSet="utf-8" />
      <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1.0, maximum-scale=1"></meta>
      <meta name="theme-color" content="#000000" />
      <meta name="keywords" content={appName} />
      <meta property="og:site_name" content={appName} key="ogsitename" />
      <meta property="og:author" content={appName} key="author" />
      <meta property="og:type" content="website" key="ogtype" />
      <meta property="og:image" content="/images/logo.png" key="ogimage" />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:image:alt" content={appName} />
      <meta property="og:locale" content="it_IT" />
      <meta property="og:locale:alternate" content="en_US" />
      <meta name="twitter:image" content="/images/logo.png" />
      <meta name="twitter:card" content="/images/logo.png" />

      <link rel="icon" href={`/favicon-${process.env.NEXT_PUBLIC_THEME}.ico`} />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`/apple-touch-icon-${process.env.NEXT_PUBLIC_THEME}.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={`/favicon-32x32-${process.env.NEXT_PUBLIC_THEME}.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={`/favicon-16x16-${process.env.NEXT_PUBLIC_THEME}.png`}
      />

      <link rel="sitemap" href="/sitemap.xml" />
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
      />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap"
        rel="stylesheet"
      />
      <title>{`${appName} | ${title}`}</title>
      <meta
        property="og:title"
        content={`${appName} | ${title}`}
        key="ogtitle"
      />
      <meta property="og:url" content="" key="ogurl" />

      <meta name="description" content={`${appName} | ${content}`} />
      <meta
        property="og:description"
        content={`${appName} | ${content}`}
        key="ogdesc"
      />
      <meta name="twitter:title" content={`${appName} | ${title}`} />
      <meta name="twitter:description" content={`${appName} | ${content}`} />

      <script src="https://unpkg.com/flowbite@1.5.2/dist/flowbite.js"></script>
    </NEXTHead>
  );
};

export default Head;
