import EastIcon from '@mui/icons-material/East';
import { useState } from 'react';

export const HomeScreenButtonCard = ({ onClick, title, description }) => {
    return (
      <button
        className="group flex items-center justify-between py-6 px-10 border border-app-grayDF rounded-lg hover:bg-app-defaultColor hover:text-app-textHover"
        onClick={onClick}
      >
        <div className="flex flex-col">
          <h3 className="text-left text-xl font-semibold">{title}</h3>
          <h4 className="text-left text-normal">{description}</h4>
        </div>
        <div className="border border-app-defaultColor p-4 rounded-lg ml-10 transform translateZ(0)">
          <EastIcon fontSize="medium"  className={"text-app-defaultColor group-hover:text-app-textHover icon-arrow-mui"}/>
        </div>
      </button>
    );
  };

